<template>
	<el-dialog class="dialog" title="新增" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="120px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="关联企业：" prop="entId">
							<el-select v-model="formData.entId" clearable :popper-append-to-body="false"
								placeholder="请选择">
								<el-option v-for="item in ents" :key="item.abbr" :label="item.abbr" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="模型名称：" prop="name">
							<el-input v-model="formData.name">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="是否有过钢：">
							<el-radio-group v-model="formData.steelStatus">
								<el-radio :label="0">否</el-radio>
								<el-radio :label="1">是</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="是否显示过钢：" v-if="formData.steelStatus==1">
							<el-radio-group v-model="formData.steelShow">
								<el-radio :label="0">否</el-radio>
								<el-radio :label="1">是</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="过钢起点：" prop="startPoint" v-if="formData.steelStatus==1">
							<el-input v-model="formData.startPoint">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="过钢终点：" prop="endPoint" v-if="formData.steelStatus==1">
							<el-input v-model="formData.endPoint">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="过钢y轴：" prop="steelHeight" v-if="formData.steelStatus==1">
							<el-input v-model="formData.steelHeight">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	// var regu = /^[0-9]+\.?[0-9]*$/;
	 var regu =/(^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:.\d{1,2})?$)/;
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				title: "",
				loading: false,
				formData: {},
				ents: [],
				rules: {
					name: [{
						required: true,
						message: '请填写名称',
						trigger: 'change'
					}],
					entId: [{
						required: true,
						message: '请选择企业',
						trigger: 'change'
					}],
					startPoint: [{
						trigger: 'change',
						validator: (rule, value, callback) => {
							if (!regu.test(value)) {
								callback(new Error('请输入正确数字'));
							} else {
								callback();
							}
						}
					}],
					endPoint: [{
						trigger: 'change',
						validator: (rule, value, callback) => {
							if (!regu.test(value)) {
								callback(new Error('请输入正确数字'));
							} else {
								callback();
							}
						}
					}],
					steelHeight: [{
						trigger: 'change',
						validator: (rule, value, callback) => {
							if (!regu.test(value)) {
								callback(new Error('请输入正确数字'));
							} else {
								callback();
							}
						}
					}],
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//获取企业列表
			getEnts() {
				this.$get("/backend-api/sys/ent/list", {
					size: 99
				}).then((res) => {
					if (res.code == 1000) {
						this.ents = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开弹窗
			open() {
				this.formData = {};
				this.getEnts();
				if (this.dialogObj.type == 2) {
					this.get();
				}
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				})
			},
			get() {
				this.$get("/backend-api/model/line/get", {
					id: this.dialogObj.row.id
				}).then((res) => {
					if (res.code == 1000) {
						this.formData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//提交表单
			submit() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$postData("/backend-api/model/line/save", this.formData).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					} else {
						return false;
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}
</style>

<template>
	<el-dialog class="dialog" title="整线配置" :visible.sync="visiable" width="80%">
		<div v-loading="loading" class="dlalog-body">
			<div style="float: right;margin: 10px 20px;">
				<el-button type="primary" @click="downloadData1">
					数据下载
				</el-button>
				<el-button type="primary" @click="downloadmodal1">
					模板下载
				</el-button>
				<el-upload style="display: inline-block;margin:0 10px;" action="" :before-upload="BeforeUpload"
					:http-request="uploadExcel1" :show-file-list="false" :file-list="fileList" :limit="1"
					accept=".xls,.xlsx">
					<el-button type="primary">导入excel</el-button>
				</el-upload>
				<el-button type="primary" @click="insert1">
					添加行
				</el-button>
				<el-button type="danger" v-if="deleteArr.length>0" @click="deleteSelect">
					删除选中
				</el-button>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData" border height="800"
							:header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ padding: 0 }"
							style="margin-bottom: 20px" @selection-change="handleSelectionChange">
							<el-table-column type="selection" width="55">
							</el-table-column>
							<el-table-column prop="id" align="center" label="ID" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="name" align="center" label="单体名称" show-overflow-tooltip>
							</el-table-column>
							<!-- <el-table-column prop="pointX" align="center" label="坐标" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>
										({{scope.row.pointX}},{{scope.row.pointY}},{{scope.row.pointZ}})
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="rotaX" align="center" label="角度" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>
										({{scope.row.rotaX}},{{scope.row.rotaY}},{{scope.row.rotaZ}})
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="scaleX" align="center" label="缩放" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>
										({{scope.row.scaleX}},{{scope.row.scaleY}},{{scope.row.scaleZ}})
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="color" align="center" label="颜色" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="opacity" align="center" label="透明度" show-overflow-tooltip>
							</el-table-column> -->
							<el-table-column prop="remark" align="center" label="说明" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="stlFile" align="center" label="模型文件" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>
										{{scope.row.stlFile? scope.row.stlFile.substr(scope.row.stlFile.lastIndexOf('/')+1):""}}
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="unitName" align="center" label="关联单体" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="unitShow" align="center" label="是否有模型" show-overflow-tooltip>
								<template slot-scope="scope">
									<div v-if="scope.row.unitShow==1">是</div>
									<div v-else>否</div>
								</template>
							</el-table-column>
							<el-table-column  align="center" label="操作" width="250">
								<template slot-scope="scope">
									<el-upload style="display: inline-block;margin-right: 10px;" action=""
										:before-upload="BeforeUploadModal" :http-request="uploadModal" :data="scope.row"
										:show-file-list="false" :file-list="fileList" :limit="1" accept=".stl">
										<el-button type="text">上传模型</el-button>
									</el-upload>
									<el-button type="text" @click="edit2(scope.row)">
										编辑
									</el-button>
									<el-button type="text" @click="remove2(scope.row)">
										删除行
									</el-button>
									<el-button type="text" @click="downloadModel(scope.row)">
										下载模型
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
			</div>
		</div>
		<edit2 :isShow.sync="showEdit2Dialog" :dialogObj="edit2Obj"></edit2>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin";
	import configApi from "@config/configApi";
	import edit2 from "./edit2.vue";
	export default {
		mixins: [dialogMixin],
		components: {
			edit2,
		},
		data() {
			return {
				title: "",
				loading: false,
				formData: {},
				tableData: [],
				tableData2: [],
				showEdit2Dialog: false,
				edit2Obj: {},
				newFile: new FormData(),
				fileList: [],
				deleteArr:[]
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.newFile = new FormData();
				this.tableData = [];
				this.tableData2 = [];
				if (this.dialogObj.type == 2) {
					this.get();
				}
			},
			get() {
				this.$get("/backend-api/model/line-comp/list", {
					typeId: this.dialogObj.row.id
				}).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//上传前校验
			BeforeUpload(file) {
				if (this.fileList.length > 0) {
					this.$message.error('只能上传一份');
					return false;
				}
				const fileType = file.name.substring(file.name.lastIndexOf('.'));
				if (fileType != '.xls' && fileType != '.xlsx') {
					this.$message.error('上传文件只能是 excel 文件格式!');
				} else if (file) {
					if(this.newFile.has("file")){
						this.newFile.delete("file");
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
				return false;
			},
			//上传模型前校验
			BeforeUploadModal(file) {
				if (this.fileList.length > 0) {
					this.$message.error('只能上传一份');
					return false;
				}
				const fileType = file.name.substring(file.name.lastIndexOf('.'));
				if (fileType != '.stl' && fileType != '.STL') {
					this.$message.error('上传文件只能是 stl 文件格式!');
				} else if (file) {
					if(this.newFile.has("file")){
						this.newFile.delete("file");
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
				return false;
			},
			uploadModal(file) {
				this.newFile.append("type", 1);
				this.newFile.append("id", file.data.id);
				this.$postData("/backend-api/model/stl/upload", this.newFile).then((res) => {
					if (res.code == 1000) {
						this.get();
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.success(res.msg);
					} else {
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.error(res.msg);
					}
				});
			},
			uploadExcel1() {
				this.newFile.append("typeId", this.dialogObj.row.id);
				this.$postData("/backend-api/model/line-comp/upload", this.newFile).then((res) => {
					if (res.code == 1000) {
						this.get();
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.success(res.msg);
					} else {
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.error(res.msg);
					}
				});
			},
			downloadData1() {
				//访问路径直接下载
				window.location.href = this.baseURL + "/backend-api/model/line-comp/download?typeId=" + this.dialogObj.row
					.id
			},
			downloadmodal1() {
				this.$get("/backend-api/model/line-comp/template").then((res) => {
					if (res.code == 1000) {
						window.location.href = this.photoURL + res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			downloadModel(file){
				window.location.href = this.photoURL+file.stlFile
			},
			//打开编辑弹窗
			edit2(row) {
				this.edit2Obj["row"] = row;
				this.edit2Obj["type"] = 2;
				this.edit2Obj["lineId"] = this.dialogObj.row.lineId;
				this.edit2Obj["unitTypeId"] = this.dialogObj.row.id;
				this.showEdit2Dialog = true;
			},
			//打开新增弹窗
			insert1() {
				this.edit2Obj["type"] = 1;
				this.edit2Obj["lineId"] = this.dialogObj.row.lineId;
				this.edit2Obj["unitTypeId"] = this.dialogObj.row.id;
				this.showEdit2Dialog = true;
			},
			remove2(row) {
				this.$confirm("是否确认删除？", "删除零部件", {
						dangerouslyUseHTMLString: true,
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$post("/backend-api/model/line-comp/delete", {
							id: row.id,
						}).then((res) => {
							if (res.code == 1000) {
								this.$message.success(res.msg);
								//刷新表格
								this.get();
							} else {
								this.$message.error(res.msg);
							}
						});
					})
					.catch(() => {});
			},
			//关闭弹窗
			close() {
				this.visiable = false;
			},
			//多选
			handleSelectionChange(val) {
				this.deleteArr = val
			},
			//多选删除
			async deleteSelect() {
			
				let arr = []
				await this.deleteArr.forEach(item => {
					arr.push(item.id)
				})
				let ids = arr.join(',')
				this.$confirm("是否确认删除？", "删除选中模型", {
						dangerouslyUseHTMLString: true,
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$post('/backend-api/model/line-comp/allDelete', {
							ids: ids
						}).then(res => {
							if (res.code == 1000) {
								this.$message.success(res.msg);
								//刷新表格
								this.get();
							} else {
								this.$message.error(res.msg);
							}
						})
					})
					.catch(() => {});
			},
		},
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss";

	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}

	.el-dialog {
		min-width: 1400px;
	}
</style>
